import React from 'react';

function Footer() {
  return (
    <footer className="bg-light text-center text-lg-start mt-4" style={{ fontFamily: 'EB Garamond, serif', fontSize: '20px' }}>
      <div className="text-center p-3">
        © 2025 Richmond Capital Partners
      </div>
    </footer>
  );
}

export default Footer;
