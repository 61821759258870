import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="parallax">
      </div>
      <div className="company-overview">
        <h2>Company Overview</h2>
        <p>
          Richmond Capital Partners (“RCP”) is Western University’s largest student-managed fund by assets under management. 
          At Richmond Capital Partners, we believe in the power of an entrepreneurial spirit and the innovative ideas that come with it.
          Founded by a group of passionate students, the fund's primary mandate is to generate outsized returns for partners while identifying the next generation of business leaders and investors.
          RCP strives to educate students at Western / Ivey and provide practical, hands-on experience in a buy-side environment. 
          RCP accomplishes this by conducting weekly pitch presentations and 
          research reports across fourteen teams, with select pitches leading to real trades in the portfolio. 
          RCP is a multi-strategy fund that trades in U.S. and Canadian equities, futures, and derivatives.
        </p>
        <p>
          Richmond Capital Partners (16031838 Canada Inc.) is a registered corporation and trades as a proprietary trading firm. RCP launched its second fund for the 2024/2025 school year, which started trading activities as of September 23, 2024.
        </p>
      </div>
    </div>
  );
}

export default Home;